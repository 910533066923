/**
 * IMPORTANT!!
 * This page is associated with the QR on my business cards.
 * Provides a list of support and social media links.
 * This should/could be generated programmatically and associated with the site footer links
 */
const QR = () => {
	return (
		<nav className="linkTree">
			<h3 className="menuTitle">Support!</h3>
			<ul id="connectionWidgets">
				<li className="firstNode">
					<a id="link_shop" className="socialLink" href="https://shop.eldonfox.com/" alt="Visit our online store." title="Visit our online store." target="_blank"  rel="noreferrer">
						Shop
					</a>
				</li>
				<li className="lastNode">
					<a id="link_patreon" className="socialLink" href="https://www.patreon.com/eldonfox/" alt="Go to Patreon." title="Go to Patreon." target="_blank"  rel="noreferrer">
						Patreon
					</a>
				</li>
			</ul>
			<h3 className="menuTitle">Connect!</h3>
			<ul id="connectionWidgets">
				<li className="firstNode">
					<a id="link_facebook" className="socialLink" href="https://www.facebook.com/EldonFoxFineArts/" alt="Go to Facebook." title="Go to Facebook." target="_blank"  rel="noreferrer">
						Facebook
					</a>
				</li>
				<li>
					<a id="link_linkedIn" className="socialLink" href="http://www.linkedin.com/in/eldonfox" alt="Go to Linked In." title="Go to Linked In." target="_blank"  rel="noreferrer">
						Linked In
					</a>
				</li>
				<li>
					<a id="link_twitter" className="socialLink" href="http://twitter.com/eldonfox" alt="Go to Twitter." title="Go to Twitter." target="_blank"  rel="noreferrer">
						Twitter
					</a>
				</li>
				<li>
					<a id="link_youtube" className="socialLink" href="http://www.eldonfox.com/youtube/" alt="Go to YouTube." title="Go to YouTube." target="_blank"  rel="noreferrer">
						YouTube
					</a>
				</li>
				<li>
					<a id="link_instagram" className="socialLink" href="https://www.instagram.com/eldonfoxfinearts/" alt="Go to Instagram." title="Go to Instagram." target="_blank"  rel="noreferrer">
						Instagram
					</a>
				</li>
			</ul>
		</nav>
	);
}

export default QR;
