import { useState } from "react";

function Contacts() {
	const [emailInputs, setEmailInputs] = useState({});
	const [responseMsg, setResponseMsg] = useState('');
	const [responseType, setResponseType] = useState(null);
	const [showForm, setShowForm] = useState(true);

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setEmailInputs(values => ({...values, [name]: value}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		fetch("/api/v1/contact",
			{
				method: "POST",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(emailInputs),
			})
		.then(response => {
			if(!response.ok) {
				setResponseMsg('Your message could not be sent.');
			}

			return response.json();
		})
		.then(data => {
			let errorList = '';

			if(data.data.errors) {
				setResponseType('error');

				Object.keys(data.data.response).forEach(k => {
					if(data.data.response[k]['error']) {
						errorList += ' ' + data.data.response[k]['errorMsg']
					}
				});
			} else {
				setResponseType('success');
				setShowForm(false);
			}

			setResponseMsg(data.data.responseMsg + errorList);
		})
		.catch(error => {
			console.log(error);
		});
	};

	return (
		<section className="tinyContent">
			<h3>Send Me a Message</h3>

			<p className={responseType}>{responseMsg}</p>

			{showForm && (
				<form method="post" onSubmit={handleSubmit} className="tinyForm">
					<div className="inputGrid">
						<label htmlFor="emailFromName">Your Name:</label>
						<input type="text" className="text" id="emailFromName" name="emailFromName" onChange={handleChange} />

						<label htmlFor="emailFrom">Your Email:</label>
						<input type="email" className="text" id="emailFrom" name="emailFrom" onChange={handleChange} />

						<label htmlFor="emailSubject">Subject:</label>
						<input type="text" className="text" id="emailSubject" name="emailSubject" onChange={handleChange} />
					</div>

					<div className="textareaSet">
						<label htmlFor="emailBody">Message:</label>
						<textarea id="emailBody" name="emailBody" rows="5" cols="25" onChange={handleChange}></textarea>
					</div>

					<div className="actionBar">
						<button>Send Email</button>
					</div>
				</form>
			)}
		</section>
	);
}

export default Contacts;
